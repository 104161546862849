<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">MEMBERSHIP</h1>
    </div>
    <div class="title-tabs mt-3">Membership Setting</div>

    <b-container class="no-gutters bg-white py-3">
      <InputText
        textFloat="Member ID Prefix"
        placeholder="Member ID Prefix"
        type="text"
        name="member_id_prefix"
        v-model="form.running_prefix"
      />

      <label class="title"> Member No. Length </label>
      <br />
      <div class="form-check form-check-inline" v-for="i in 6" :key="i">
        <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="inlineRadio1"
          :value="i + 5 - 1"
          v-model="form.running_no_length"
        />
        <label class="form-check-label" for="inlineRadio1">
          {{ i + 5 - 1 }}
        </label>
      </div>
      <section>
        <div class="title">Accept Variable</div>
        <p>
          {{ "{" }}{MM}} : 2 Digit of Month of Transaction eg. 01,02,12 <br />
          {{ "{" }}{YY}} : 2 Digit of Year of Transaction eg. Year 2020 :
          20,22,23
        </p>
      </section>
    </b-container>
    <FooterAction routePath="/setting" @submit="saveForm()" />

    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/ModalLoading";
export default {
  components: { InputText, ModalLoading },
  data() {
    return {
      form: {
        running_prefix: "",
        running_no_length: "",
      },
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    async getList() {
      const response = await this.axios(`/setting/membership`);
      this.form = response.data.detail;
    },

    async saveForm() {
      this.$refs.modalLoading.show();
      this.axios.post(`/setting/membership`, this.form).then((res) => {
        if (res.data.result) {
          this.successAlert().then(
            (val) => val && this.$router.push("/setting")
          );
        } else {
          this.errorAlert(res.data.message);
        }
        this.$refs.modalLoading.hide();
      });
    },
  },
};
</script>
<style>
label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
</style>
